<template>
    <v-container fluid grid-list-lg v-cloak>
        <v-layout row wrap>
            <v-card color="basil" width="100%">
              <v-toolbar :color="($vuetify.theme.dark) ? 'dark' : 'white'">
                <v-toolbar-title>Grand Weekly Tests</v-toolbar-title>
              </v-toolbar>
              <!--                <v-card-title primary-title width="100%">-->
              <!--                    <div style="width:100%">-->
              <!--                        <div class="headline indigo&#45;&#45;text">-->
              <!--                            Grand Weekly Tests-->
              <!--                            <hr class="red darken-1"/>-->
              <!--                        </div>-->
              <!--                    </div>-->
              <!--                </v-card-title>-->

              <v-tabs
                  v-model="tab"
                  background-color="transparent"
                  color="basil"
                  grow
              >
                <v-tab
                    v-for="item in tabs"
                    :key="item"
                >
                  {{ item }}
                </v-tab>
              </v-tabs>

              <v-tabs-items v-model="tab">
                <v-tab-item>
                  <LiveUpcomingTests :items="items" :subscribed="subscribed"/>
                </v-tab-item>
                <v-tab-item>
                        <PreviousTests :items="items" :show-progress="showProgress" :subscribed="subscribed"/>

                    </v-tab-item>

                </v-tabs-items>
                <v-card-text style="height: 100px; position: relative">

                </v-card-text>
            </v-card>


        </v-layout>

    </v-container>
</template>


<script>
    // import TestsDisplayExpansionItem from "./TestsDisplayExpansionItem";

    import LiveUpcomingTests from "../components/LiveUpcomingTests";
    import PreviousTests from "../components/PreviousTests";
    import {APIService} from "../APIService";

    const apiService = new APIService();

    export default {
        metaInfo: {
            title: 'Weekly Tests',
            titleTemplate: '%s - Dental Pockets ||| Mastering Dentisty the Easy Way!',
            htmlAttrs: {
                lang: 'en',
                amp: true
            },
            meta: [
                {httpEquiv: 'Content-Type', content: 'text/html; charset=utf-8'},
                {
                    name: 'description',
                    content: "NEET, NBDE, Dental Exams, PGI, JIPMER, DHA, preparation of dental exams now easy. Image based questions are now made easy."
                }
            ]
        },
        name: "WeeklyTests",
        components: {PreviousTests, LiveUpcomingTests},
        // components: {TestsDisplayExpansionItem},
        data() {
            return {
                subscribed: false,
                pageno: 1,
                prevPageNo: 2,
                bottom: false,
                showProgress: false,
                items: {
                    upcoming: [],
                    past: []
                },
                // items: {
                //     upcoming: [{
                //         date: "2",
                //         month: "Jan",
                //         title: "NEET MOCK TEST",
                //         sub: "Full length NEET mock test",
                //         live: true,
                //         subTime: "Starts at 2 Jan 00:00:01 and ends at 23:59:59",
                //         noOfQ: "40",
                //         misc: ""
                //     }],
                //     past: [
                //         {
                //             date: "1",
                //             month: "Jan",
                //             title: "NEET MOCK TEST",
                //             sub: "Full length NEET mock test",
                //             live: false,
                //             subTime: "Started at 1 Jan 00:00:01 and ends at 23:59:59",
                //             noOfQ: "40",
                //             misc: ""
                //         }
                //     ],
                // },
                tab: null,
                tabs: [
                    'Live & Upcoming', 'Previous',
                ],
                text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
            }
        },
        mounted() {
            this.$nextTick(function () {
                this.$store.commit("setShowProgress", true);

                if (localStorage.getItem("uid") === null) {
                    //
                    this.$router.push({name: "login"})
                } else {
                    // Code that will run only after the
                    // entire view has been rendered
                    this.getUpcomingWeeklyTestsFromServer();


                }


            });
        },
        methods: {
            getUpcomingWeeklyTestsFromServer() {

                const app = this;
                apiService.getUpcomingWeeklyTests(app.pageno).then(data => {

// eslint-disable-next-line no-console
//                     console.log(data.data);
                    // eslint-disable-next-line no-console
                    // console.log(data.data.upcoming[0].attempted);
                    app.items = data.data;
                    app.subscribed = data.subscribed;

                    app.$store.commit("setSubscriptionValidTill", data.expiry);
                    app.$store.commit("setSubscriptionValidFrom", data.validFrom);


                    localStorage.setItem("isSubscribed", data.subscribed);

                    app.$store.commit("setShowProgress", false);

                });

            },

            loadMore() {
                if (this.tab === 1) {
                    this.showProgress = true;
                    // eslint-disable-next-line no-console
                    // console.log("Loading more previous tests.......");


                    const app = this;
                    apiService.getUpcomingPreviousWeeklyTests(app.prevPageNo).then(data => {

                        if (!data.data.past.length > 0) {
                            // eslint-disable-next-line no-console
                            // console.log("Data caome empty");
                            this.showProgress = false;
                            return;
                        }
                        app.prevPageNo = app.prevPageNo + 1;
                        // eslint-disable-next-line no-console
                        // console.log(data.data);
                        data.data.past.forEach(element => {
                            this.items.past.push(element);
                        });
                        this.showProgress = false;

                        // app.items = data.data;
                        // app.subscribed = data.subscribed;
                    });


                    // let data = [{
                    //     date: "1",
                    //     month: "Jan",
                    //     title: "NEET MOCK TEST",
                    //     sub: "Full length NEET mock test",
                    //     live: false,
                    //     subTime: "Started at 1 Jan 00:00:01 and ends at 23:59:59",
                    //     noOfQ: "40",
                    //     misc: ""
                    // }, {
                    //     date: "31",
                    //     month: "Dec",
                    //     title: "NEET MOCK TEST 2",
                    //     sub: "Full length NEET mock test",
                    //     live: false,
                    //     subTime: "Started at 31 Dec 00:00:01 and ends at 23:59:59",
                    //     noOfQ: "20",
                    //     misc: ""
                    // }];
                    // data.forEach(element => {
                    //     this.items.past.push(element);
                    // });


                } else {
                    //    It is Upcoming tests.
                }


            },
            bottomVisible() {
                const scrollY = window.scrollY;
                const visible = document.documentElement.clientHeight;
                const pageHeight = document.documentElement.scrollHeight;
                const bottomOfPage = visible + scrollY >= pageHeight;
                return bottomOfPage || pageHeight < visible
            },
        },
        created() {
            if (localStorage.getItem("isSubscribed")) {
                this.subscribed = JSON.parse(localStorage.getItem("isSubscribed"));
            }
            window.addEventListener('scroll', () => {
                this.bottom = this.bottomVisible()
            });
            this.loadMore();


        },

        watch: {
            bottom(bottom) {
                if (bottom) {
                    this.loadMore()
                }
            }
        },
    }
</script>


<style scoped>

</style>